exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dynamic-article-detail-tsx": () => import("./../../../src/pagesDynamic/articleDetail.tsx" /* webpackChunkName: "component---src-pages-dynamic-article-detail-tsx" */),
  "component---src-pages-dynamic-category-detail-tsx": () => import("./../../../src/pagesDynamic/categoryDetail.tsx" /* webpackChunkName: "component---src-pages-dynamic-category-detail-tsx" */),
  "component---src-pages-dynamic-story-detail-tsx": () => import("./../../../src/pagesDynamic/storyDetail.tsx" /* webpackChunkName: "component---src-pages-dynamic-story-detail-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */)
}

